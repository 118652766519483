import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import App from '../App';

// Switch between one screen to another screen
const ProjectRoutes = (props) => {
  return(
  <BrowserRouter>
    <Route
      exact
      path="/"
      component={props => <App favourite={null} />}
    />
     <Route
      exact
      path="/favourite"
      component={props => <App favourite="favourite"/>}
    />
  </BrowserRouter>
)
}

export default ProjectRoutes
