import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux'

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const CardComponent = (props) => {
  const { searchItems, favouriteData } = props
  var [selected, setSelected] = useState([])
  var [selectedItem, setSelectedItem] = useState([])

  const handleFavourate = (id) => {
    if (!(favouriteData.selected && favouriteData.selected.includes(id))) {
      searchItems.filter((item, index) => {
        if (item.id === id) {
          setSelected(selected = [...selected, item.id])
          setSelectedItem(selectedItem = [...selectedItem, item])
          return item
        }
      })
      props.dispatch({ type: "GET_FAVOURITE_REQUESTED", payload: {selectedItem: selectedItem, selected: selected} })
    } else {
      favouriteData.selected.splice(favouriteData.selected.indexOf(id), 1)
      favouriteData.selectedItem.filter((item, index) => {
        if (item.id === id)
            favouriteData.selectedItem.splice(index, 1)
      })
      props.dispatch({ type: "GET_FAVOURITE_REQUESTED", payload: {selectedItem: favouriteData.selectedItem, selected: favouriteData.selected} })
    }
  }

  return (
    <>
      {searchItems.map((item) => (
        <Card.Grid style={gridStyle} className="wraper">
          <div className="img_warp">
            <img src={item.image_url} />
          </div>
          <div className="content_wrap">
            <h3>{item.name}</h3>
            {!props.favourite && <span className="star_icon" onClick={(id) => handleFavourate(item.id)}> <i id={`fa${item.id}`} className={favouriteData.selected && favouriteData.selected.includes(item.id) ? "fa fa-star-o golden" : "fa fa-star-o"} style={{ fontSize: "16px" }}></i> </span>}
            <div className="content">
              {item.description}
            </div>
          </div>
        </Card.Grid>
      ))}
      {!searchItems.length && <div className="no_result"> No item Present in the list</div>}
    </>
  )
}
const mapStateToProps = state => ({
  favouriteData: state.favourite.favourite
})

export default connect(mapStateToProps)(CardComponent)
