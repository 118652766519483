import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import CardComponent from './components/cardComponent'
import Header from './components/Header'
import { Layout, Col, Input, Row, Button, Card } from 'antd';
import 'antd/dist/antd.css';
import './index.css';
const { Content } = Layout;

const App = (props) => {

  const { search } = props.search
  const [searchVal, setVal] = useState(null);

  const handleChange = (event) => {
      setVal(event.target.value)
      if (event.target.value) {
        let typeVal = event.target.value
        let val = search.filter((item) => item.name.toLocaleLowerCase().includes(typeVal))
        props.dispatch({ type: "GET_SEARCH_REQUESTED", payload: val })
      } else {
        props.dispatch({ type: "GET_SEARCH_REQUESTED", payload: null })
      }
  }

  useEffect(() => {
      props.dispatch({ type: "GET_SEARCH_REQUESTED", payload: null })
  }, []);
  return (
    <Layout>
      <Header />
      {!props.favourite && <Row className="search_bar_wrap">
        <Col className="left-search-input" xl={12} >
          <Input onChange={handleChange} value={searchVal} />
        </Col>
        <Col className="right-btn-wrap" xl={4} >
          <Button type="primary"> Search </Button>
        </Col>
      </Row>}
      <Content>
        {props.favourite && <CardComponent searchItems={props.favouriteData.selectedItem} {...props} />}
        {!props.favourite && <CardComponent searchItems={search}  {...props} />}
      </Content>
    </Layout>
  );
};

const mapStateToProps = state => ({
  search: state.search,
  favouriteData: state.favourite.favourite
})

export default connect(mapStateToProps)(App)