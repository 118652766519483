import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
const Header = () => {
    return (
        <Row className="header_wrapper">
            <Col className="left-header-text" xl={20} >Beans Lover Beers</Col>
            <Col className="left-header-right" xl={4} >
                <Col className="Home" xl={12}> <Link to="/" className="home-route"> Home </Link> </Col>
                <Col className="Favourate" xl={12}>  <Link to="/favourite" className="fav">  Favourite </Link> </Col>
            </Col>
        </Row>
    )
}

export default Header