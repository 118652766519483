import { put, takeEvery, call } from 'redux-saga/effects'
import axios from 'axios'

const API_DATA =  async (payload) => {
    const { data } = await axios({
        method: 'get',
        url: 'https://api.punkapi.com/v2/beers',  
    })
    return data
  }

 const favouriteSaga = function* favouriteSaga() {
  yield takeEvery('GET_FAVOURITE_REQUESTED', function* (action) {
    try {
      var DATA
      if(action.payload){
        DATA = action.payload
      }else{
        DATA = yield call(API_DATA.bind(this, action.payload))
      }
      yield put({ type: 'GET_FAVOURITE_SUCCESS', payload: DATA })
    } catch (error) {
      console.log('ERRR>>>>', error)
      yield put({ type: 'GET_FAVOURITE_FAILED' })
    }
  })
}

export default favouriteSaga;
